<template>
  <super-admin-page-card
    pageName="Logo Upload"
    iconClass="cuis-cloud-upload card-icon-font"
  >
    <div>
      <b-tabs v-model="tabIndex">
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">FI Logo</h6>
          </template>
          <fi-logo-upload></fi-logo-upload>
        </b-tab>
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">Vendor Logo</h6>
          </template>
          <vendor-logo-upload></vendor-logo-upload>
        </b-tab>
      </b-tabs>
    </div>
  </super-admin-page-card>
</template>

<script>
// base
import { mapGetters } from 'vuex'
import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'
//ui components
import FiLogoUpload from './FiLogoUpload'
import VendorLogoUpload from './VendorLogoUpload'

export default {
  name: 'LogoUpload',
  components: {
    FiLogoUpload,
    VendorLogoUpload
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/manageSuperAdminBoard']) {
      next()
    } else {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    if (this.$route.query.vendor_id) {
      this.tabIndex = 1
    }
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    ...mapGetters('Ability', ['manageSuperAdminBoard'])
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
/deep/ .logo-upload {
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-label {
    font-weight: bold;
    color: white;
  }

  .old-logo,
  .new-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 5rem;
      max-height: 5rem;
      border-radius: 5px;
      border-style: ridge;
      object-position: center;
      object-fit: cover;
      vertical-align: -webkit-baseline-middle;
    }
  }

  .logo-direction {
    font-size: 3rem;
  }

  .disable-upload {
    opacity: 0.65;
    cursor: not-allowed !important;
    pointer-events: none;
  }
}
</style>
