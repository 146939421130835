<template>
  <div>
    <div class="logo-upload">
      <div class="row">
        <div class="col-sm-6">
          <div class="row mb-4">
            <div class="col-sm-4 align-self-center">
              Select Institution:
            </div>
            <div class="col-sm-8 pl-0">
              <multiselect
                v-model="selectedInstitution"
                label="full_name"
                track-by="id"
                placeholder="Type FI name to search"
                :options="institutions"
                :multiple="false"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :limit="3"
                :show-no-results="true"
                :hide-selected="true"
                @search-change="searchInstitutions"
                @select="previewOldLogo"
              >
                <span slot="noResult">
                  No matching institutions found...
                </span>
              </multiselect>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-4 align-self-center">
              Choose Logo:
            </div>
            <div class="col-sm-6 pl-0">
              <input
                type="file"
                name="logo"
                id="logo"
                class="inputfile"
                ref="logo"
                @change="handleFileUpload()"
              />
              <label
                for="logo"
                :class="[
                  'btn btn-primary fw-btn rounded mb-0 file-label',
                  selectedInstitution ? 'enable-upload' : 'disable-upload'
                ]"
              >
                <i class="cuis-cloud-upload pr-1"></i>
                Choose a logo
              </label>
              <span class="pl-1 font-weight-bold" v-if="logo">
                [{{ logo.name }}]
              </span>
            </div>
          </div>
        </div>
        <div class="col-sm-1"></div>
        <div
          :class="['col-sm-5', selectedInstitution ? 'visible' : 'invisible']"
        >
          <div class="row text-center">
            <div class="col-sm-7 pb-3 font-weight-bold">
              CURRENT / NEW LOGO
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 d-flex align-items-center">
              <div class="old-logo" ref="oldLogo" id="old-logo">
                <img src="dummyImage" alt="No logo." />
              </div>
            </div>
            <div class="col-sm-2 d-flex align-items-center px-2">
              <i class="cuis-arrow-right logo-direction"></i>
            </div>
            <div class="col-sm-3 d-flex align-items-center">
              <div class="new-logo" ref="newLogo" id="new-logo">
                <img :src="uploadLogoData" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3 pt-2">
        <div class="col-sm-12 text-center mt-5">
          <b-button
            size=""
            variant="primary"
            @click="submitFile()"
            class="fw-btn"
            >Submit</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fiAPI from '@/modules/core/api/financial_institution'
import Multiselect from 'vue-multiselect'

export default {
  name: 'FiLogoUpload',
  components: {
    Multiselect
  },
  data() {
    return {
      institutions: [],
      selectedInstitution: null,
      isLoading: false,
      logo: null,
      newLogoData: null,
      dummyImage:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAf0lEQVR42u3QQREAMAgAoNm/5rSGdvB8QgTiZ/VjLQQKFChQIAIFChSIQIECBSJQoECBCBQoUCACBQoUiECBAgUiUKBAgQgUKFAgAgUKFIhAgQIFChQoUKBAgQgUKFAgAgUKFIhAgQIFIlCgQIEIFChQIAIFChSIQIECBSLwzgARUibAKUVHhwAAAABJRU5ErkJggg=='
    }
  },
  created() {
    this.searchInstitutions('')
    if (this.$route.query.fi_id) {
      this.setDefaultInstitution()
    }
  },
  computed: {
    uploadLogoData() {
      return this.newLogoData || this.dummyImage
    },
    validImageFormats() {
      return ['image/png', 'image/jpg', 'image/jpeg']
    }
  },
  methods: {
    setDefaultInstitution() {
      fiAPI.show(this.$route.query.fi_id).then(res => {
        this.selectedInstitution = res.financial_institution
        this.previewOldLogo(this.selectedInstitution)
      })
    },
    searchInstitutions(searchText) {
      this.isLoading = true
      fiAPI
        .search(searchText, 0)
        .then(res => {
          this.institutions = res.financial_institutions
        })
        .then(() => {
          this.isLoading = false
        })
    },
    previewOldLogo(institution) {
      if (institution) {
        this.removeOldLogoPreview()

        let logoUrl = `https://finapps-logos.s3.amazonaws.com/logo_${institution.id}.png`
        let logo = new window.Image()

        logo.crossOrigin = 'Anonymous'
        logo.onload = () => {
          this.$refs.oldLogo.appendChild(logo)
        }

        logo.onerror = () => {
          logo.src = this.dummyImage
          this.$refs.oldLogo.appendChild(logo)
        }

        logo.src = logoUrl
      }
    },
    removeOldLogoPreview() {
      let oldImg = document.getElementById('old-logo').firstChild
      if (oldImg) {
        oldImg.remove()
      }
    },
    handleFileUpload() {
      let chosenFile = this.$refs.logo.files[0]

      if (this.validImageFormats.includes(chosenFile.type)) {
        this.logo = chosenFile
        const reader = new FileReader()
        reader.onload = e => {
          this.newLogoData = e.target.result
        }
        reader.readAsDataURL(this.logo)
      } else {
        this.$toasted.show(
          'Invalid image format, please choose png/jpeg images...',
          {
            icon: 'chain-broken',
            type: 'error'
          }
        )
      }
    },
    submitFile() {
      let formData = new FormData()

      formData.append('financial_institution[id]', this.selectedInstitution.id)
      formData.append('financial_institution[logo]', this.logo)

      this.$http
        .post('/api/super_admin/financial_institutions/upload_logo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          handleErrors: true
        })
        .then(() => {
          this.resetFIData()
          this.$toasted.show(
            'Financial Institution logo successfully uploaded...',
            {
              icon: 'user-circle',
              type: 'success'
            }
          )
        })
    },
    resetFIData() {
      this.logo = null
      this.newLogoData = null
      this.selectedInstitution = null
    }
  }
}
</script>

<style lang="scss" scoped></style>
