import axios from 'axios'

export default {
  all() {
    return axios
      .get('/api/super_admin/bank_service_vendors', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  uploadLogo(formData) {
    return axios.post(
      '/api/super_admin/bank_service_vendors/upload_logo',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        handleErrors: true
      }
    )
  }
}
