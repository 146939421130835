<template>
  <div>
    <div class="logo-upload">
      <div class="row">
        <div class="col-sm-6">
          <div class="row mb-4">
            <div class="col-sm-4 align-self-center">
              Select Vendor:
            </div>
            <div class="col-sm-8 pl-0">
              <multiselect
                v-model="selectedVendor"
                label="name"
                track-by="id"
                placeholder="Type Vendor name to search"
                :options="bankServiceVendors"
                :multiple="false"
                :loading="isLoading"
                :internal-search="true"
                :limit="3"
                :show-no-results="true"
                :hide-selected="true"
                @select="previewOldVendorLogo"
              >
                <span slot="noResult">
                  No matching vendors found...
                </span>
              </multiselect>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-4 align-self-center">
              Choose Logo:
            </div>
            <div class="col-sm-6 pl-0">
              <input
                type="file"
                name="vendorLogo"
                id="vendorLogo"
                class="inputfile"
                ref="vendorLogo"
                @change="handleFileUpload()"
              />
              <label
                for="vendorLogo"
                :class="[
                  'btn btn-primary fw-btn rounded mb-0 file-label',
                  selectedVendor ? 'enable-upload' : 'disable-upload'
                ]"
              >
                <i class="cuis-cloud-upload pr-1"></i>
                Choose a logo
              </label>
              <span class="pl-1 font-weight-bold" v-if="vendorLogo">
                [{{ vendorLogo.name }}]
              </span>
            </div>
          </div>
        </div>
        <div class="col-sm-1"></div>
        <div :class="['col-sm-5', selectedVendor ? 'visible' : 'invisible']">
          <div class="row text-center">
            <div class="col-sm-7 pb-3 font-weight-bold">
              CURRENT / NEW LOGO
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 d-flex align-items-center">
              <div class="old-logo" ref="oldVendorLogo" id="old-vendor-logo">
                <img src="dummyImage" alt="No logo." />
              </div>
            </div>
            <div class="col-sm-2 d-flex align-items-center px-2">
              <i class="cuis-arrow-right logo-direction"></i>
            </div>
            <div class="col-sm-3 d-flex align-items-center">
              <div class="new-logo" ref="newVendorLogo" id="new-vendor-logo">
                <img :src="uploadLogoData" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3 pt-2">
        <div class="col-sm-12 text-center mt-5">
          <b-button
            size=""
            variant="primary"
            @click="submitFile()"
            class="fw-btn"
            >Submit</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
//api
import vendorAPI from '@/api/finapps/super_admin/bank_service_vendors'

export default {
  name: 'FiLogoUpload',
  components: {
    Multiselect
  },
  data() {
    return {
      bankServiceVendors: [],
      selectedVendor: null,
      isLoading: false,
      vendorLogo: null,
      newLogoData: null,
      dummyImage:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAf0lEQVR42u3QQREAMAgAoNm/5rSGdvB8QgTiZ/VjLQQKFChQIAIFChSIQIECBSJQoECBCBQoUCACBQoUiECBAgUiUKBAgQgUKFAgAgUKFIhAgQIFChQoUKBAgQgUKFAgAgUKFIhAgQIFIlCgQIEIFChQIAIFChSIQIECBSLwzgARUibAKUVHhwAAAABJRU5ErkJggg=='
    }
  },
  created() {
    this.getVendorsList().then(() => {
      let vendorId = this.$route.query.vendor_id

      if (vendorId) {
        this.selectedVendor = this.bankServiceVendors.find(
          bsv => bsv.id === vendorId
        )
        this.previewOldVendorLogo(this.selectedVendor)
      }
    })
  },
  computed: {
    uploadLogoData() {
      return this.newLogoData || this.dummyImage
    },
    validImageFormats() {
      return ['image/png', 'image/jpg', 'image/jpeg']
    }
  },
  methods: {
    getVendorsList() {
      this.isLoading = true
      return vendorAPI
        .all()
        .then(res => {
          this.bankServiceVendors = res.vendors
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    previewOldVendorLogo(vendor) {
      if (vendor) {
        this.removeOldVendorLogoPreview()

        let logoUrl = `https://finapps-vendor-logos.s3.amazonaws.com/logo_${vendor.id}.png`
        let logo = new window.Image()

        logo.crossOrigin = 'Anonymous'
        logo.onload = () => {
          this.$refs.oldVendorLogo.appendChild(logo)
        }

        logo.onerror = () => {
          logo.src = this.dummyImage
          this.$refs.oldVendorLogo.appendChild(logo)
        }

        logo.src = logoUrl
      }
    },
    removeOldVendorLogoPreview() {
      let oldImg = document.getElementById('old-vendor-logo').firstChild
      if (oldImg) {
        oldImg.remove()
      }
    },
    handleFileUpload() {
      let chosenFile = this.$refs.vendorLogo.files[0]

      if (this.validImageFormats.includes(chosenFile.type)) {
        this.vendorLogo = chosenFile
        const reader = new FileReader()
        reader.onload = e => {
          this.newLogoData = e.target.result
        }
        reader.readAsDataURL(this.vendorLogo)
      } else {
        this.$toasted.show(
          'Invalid image format, please choose png/jpeg images...',
          {
            icon: 'chain-broken',
            type: 'error'
          }
        )
      }
    },
    submitFile() {
      let formData = new FormData()

      formData.append('bank_service_vendor[id]', this.selectedVendor.id)
      formData.append('bank_service_vendor[logo]', this.vendorLogo)

      vendorAPI.uploadLogo(formData).then(() => {
        this.$toasted.show('Vendor logo successfully uploaded...', {
          icon: 'chain-broken',
          type: 'success'
        })
        this.resetVendorData()
      })
    },
    resetVendorData() {
      this.selectedVendor = null
      this.vendorLogo = null
      this.newLogoData = null
    }
  }
}
</script>

<style lang="scss" scoped></style>
